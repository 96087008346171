<template>
    <div class="cookie-policy-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Our Cookie Policy <i class="fas fa-cookie-bite"></i></h1>
                        <h4>This Policy shall be read, considered and interpreted with the Website Terms and Conditions and Privacy and Data Protection Policy.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <div class="flex-row justify-content-center">
                        <ol>
                             <li class="section-container"><b>COOKIES</b>
                                <ol>
                                    <li>We reserve the right to use cookies.</li>
                                    <li>A cookie is a group of data serving as the Registered User's anonymous individual identifier that is sent by its browser Platform. Cookies are sent when the Registered User accesses the Platform. They are stored on the Registered User's computer and serve to record information about the Registered User's subsequent online visits. Therefore, after a cookie has been stored on the Registered User's computer every time the Registered User returns to the Platform, it shall look for the cookie in order to read the stored data.</li>
                                    <li>A cookie is an anonymous individual identifier; it does not contain or send any personal information to the Platform that is stored on a Registered User's computer but only enables faster and more efficient activation of information, data and settings previously communicated during access and use of the Platform.</li>
                                    <li>The Registered User can modify or disable cookies through the Options or Settings of their browser that allows the Registered User to select an appropriate option for receiving cookies or even to disable them entirely. However, disabling cookies completely will reduce the efficiency of some content available on some Platform.</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "cookie-policy",
    metaInfo() {
        return {
            title: "DrRemo | Cookie Policy",
            meta: [
                {
                    name: "description",
                    content: "Our policy as it pertains to the use of cookies on our website."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/cookie"
                }
            ]
        }
    }
}
</script>